<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Клиент</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0" v-if="isNotCreated">{{ user.first_name }} {{ user.last_name }}</h5>
          <h5 class="mb-0" v-else>Новый пользователь</h5>
        </div>
      </div>
      <div class="card-body">
        <a-form-model ref="form" :model="user">
          <a-form-model-item class="text_area" label="Аватар">
            <a-upload
              :disabled="!!$route.query.is_preview || $route.params.id !== '0'"
              list-type="picture-card"
              :file-list="fileListMain"
              @change="handleChangeList"
            >
              <div v-if="fileListMain.length < 1 && !$route.query.is_preview && $route.params.id === '0'">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Имя" required>
            <a-input v-model="user.first_name" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
          <a-form-model-item label="Фамилия" required>
            <a-input v-model="user.last_name" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
          <a-form-model-item label="Телефон" required>
            <a-input v-model="user.phone" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
          <a-form-model-item label="Email" required>
            <a-input v-model="user.email" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
          <a-form-model-item label="Тип юзера" required>
            <a-select v-model="user.type_user" :disabled="!!$route.query.is_preview" placeholder="выберите статус">
              <a-select-option v-for="type in usersTypes" :key="type.name" :value="type.name">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Активность" required>
            <a-select v-model="user.is_blocked" :disabled="!!$route.query.is_preview" placeholder="выберите значение">
              <a-select-option v-for="type in permissions" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Город">
            <a-select v-model="user.city_id"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      :disabled="!!$route.query.is_preview"
                      allowClear show-search
                      style="width: 100%"
                      @search="handleSearch"
                      placeholder="выберите город">
              <a-select-option v-for="city in getCurrentCities" :key="city.id" :value="city.id">
                {{ city.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="Viber">
            <a-input v-model="user.phone_viber" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
          <a-form-model-item label="Telegram">
            <a-input v-model="user.phone_telegram" :disabled="!!$route.query.is_preview" />
          </a-form-model-item>
        </a-form-model>
        <div v-if="isNotCreated">
          <h3>Объявления пользователя</h3>
          <products-table :fromClient="true" :list="products.list" :isOverview="$route.query.is_preview" @editItem="editItem" @toPreview="toPreview" @removeItem="removeItem" :paginate="products.pagination" @handleTableChange="handleTableChange" />
          <h3>Пополнения баланса пользователя</h3>
          <button type="button" class="payment-btn" @click="toAddPayment(user.id)">Пополнить</button>
          <clients-payment-table :list="payments.list" :paginate="payments.pagination" @handleTableChange="handleTableChangePayments" />
          <h3>Счета к оплате пользователя</h3>
          <clients-bills-table :list="bills.list" :paginate="bills.pagination" :isOverview="$route.query.is_preview" @handleTableChange="handleTableChangeBills" @removeItem="removeClientBill" />
        </div>
        <a-button v-if="!this.$route.query.is_preview" class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          {{ this.$route.params.id !== '0' ? 'Сохранить изменения' : 'Добавить'}}
        </a-button>
      </div>
    </div>
    <product-edit-modal ref="editModal" />
    <product-preview-modal ref="previewModal" />
    <addClientsPaymentModal ref="paymentModal"/>
  </div>
</template>

<script>
import ProductsTable from '@/components/products/ProductsTable'
import ProductPreviewModal from '@/components/products/ProductPreviewModal'
import ProductEditModal from '@/components/products/ProductEditModal'
import ClientsPaymentTable from '@/components/users/ClientsPaymentTable'
import ClientsBillsTable from '@/components/users/ClientsBillsTable'
import addClientsPaymentModal from '@/components/users/addClientsPaymentModal'

import users from '@/mixins/users'
import permissions from '@/entities/permissions'

export default {
  name: 'ClientPage',
  mixins: [users],
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        is_blocked: '',
        type_user: '',
        city_id: '',
        phone_viber: '',
        phone_telegram: '',
      },
      cities: [],
      usersTypes: [],
      fileListMain: [],
      search: '',
      permissions,
      products: {
        list: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
        },
      },
      payments: {
        list: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
        },
      },
      bills: {
        list: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
        },
      },
    }
  },
  computed: {
    isDisabled() {
      let isDisabled = false

      for (const key in this.news) {
        if (!`${this.news[key]}` && (key !== 'city_id' || key !== 'phone_viber' || key !== 'phone_telegram')) isDisabled = true
      }

      return isDisabled
    },
    getCurrentCities() {
      return this.cities
    },
  },
  async created() {
    if (this.isNotCreated) await Promise.all([this.getUserInfo(), this.getUserProducts(), this.getUserPayments(), this.getUserBills()])

    await Promise.all([this.getUsersTypes(), this.getCities()])
  },
  methods: {
    async getUserInfo() {
      try {
        this.user = (await this.$services.get(`admin/users/${this.$route.params.id}`)).data.data.user

        if (this.user.image) {
          this.fileListMain.push({
            url: this.user.image,
            uid: '-4',
            name: 'image.png',
            status: 'done',
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCities() {
      const params = {
        search: this.search,
      }

      try {
        this.cities = (await this.$services.get('/filters/cities', { params })).data.data.cities
      } catch (e) {
        console.log(e)
      }
    },
    async getUsersTypes () {
      try {
        this.usersTypes = (await this.$services.get('auth/type_user')).data.data.type_user
      } catch (e) {
        console.log(e)
      }
    },
    async removeItem(record) {
      try {
        await this.$services.delete(`admin/products/${record.id}`)
        await this.getUserProducts()
      } catch (e) {
        console.log(e)
      }
      console.log('record', record)
    },
    handleSearch(value) {
      this.search = value
    },
    async submit() {
      if (this.fileListMain[0] && this.fileListMain[0].thumbUrl) this.user.icon = this.fileListMain[0].thumbUrl

      if (this.user.type_user) {
        const type = this.usersTypes.find(item => item.name === this.user.type_user)
        this.user.type_user_id = type.id
      }

      try {
        this.$route.params.id === '0' ? await this.$services.post('admin/users', this.user) : await this.$services.put(`admin/users/${this.user.id}`, this.user)
        this.$router.back()
      } catch (e) {
        console.log(e)
      }
    },
    async removeClientBill(id) {
      try {
        await this.$services.delete(`admin/userPublicationTariffs/${id}`)
        await this.getUserBills()
      } catch (e) {
        console.log(e)
      }
    },
    async getUserProducts() {
      const params = {
        page: this.products.pagination.current,
      }
      try {
        const products = (await this.$services.get(`admin/getProducts/${this.$route.params.id}`, { params })).data.data.products
        this.products = {
          list: products.data,
          pagination: {
            current: products.pagination.current_page,
            total: products.pagination.total,
            pageSize: products.pagination.per_page,
          },
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getUserPayments() {
      const params = {
        page: this.payments.pagination.current,
      }
      try {
        const payments = (await this.$services.get(`admin/historyTransactions/${this.$route.params.id}`, { params })).data.data.values
        this.payments = {
          list: payments.data,
          pagination: {
            current: payments.pagination.current_page,
            total: payments.pagination.total,
            pageSize: payments.pagination.per_page,
          },
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getUserBills() {
      const params = {
        page: this.bills.pagination.current,
      }
      try {
        const bills = (await this.$services.get(`admin/users/${this.$route.params.id}/userPublicationTariffs`, { params })).data.data.values
        this.bills = {
          list: bills.data,
          pagination: {
            current: bills.pagination.current_page,
            total: bills.pagination.total,
            pageSize: bills.pagination.per_page,
          },
        }
      } catch (e) {
        console.log(e)
      }
    },
    toPreview(id) {
      this.$refs.previewModal.open(id)
    },

    toAddPayment(id) {
      this.$refs.paymentModal.open(id)
    },

    handleTableChange(pagination) {
      this.products.pagination.current = pagination.current
      this.getUserProducts()
    },
    handleTableChangePayments(pagination) {
      console.log('pagination', pagination)
      this.payments.pagination.current = pagination.current
      this.getUserPayments()
    },
    handleTableChangeBills(pagination) {
      this.bills.pagination.current = pagination.current
      this.getUserBills()
    },
    editItem(id) {
      this.$refs.editModal.open(id)
    },
  },
  watch: {
    search() {
      this.getCities()
    },
  },
  components: { ProductsTable, ProductPreviewModal, ProductEditModal, ClientsPaymentTable, ClientsBillsTable, addClientsPaymentModal },
}
</script>

<style scoped lang="scss">
.ant-form {
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}

.payment-btn{
  color: #fff;
  background-color: #4CAD33;
border: 0;

&:hover,
&:focus {
  background-color: #41b823;
}
}
</style>
