<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >{{text}}</a>
    <span slot="name" slot-scope="record">
      <span>{{ record.level_tariff ? `Пакет продвижения "${record.level_tariff}"` :  `Пакет публикаций "${record.publication_tariff}"` }}</span>
      <div>ID: {{ record.unique_id }}</div>
    </span>
    <span slot="price" slot-scope="record"> {{ record.price }}</span>
    <span slot="date" slot-scope="record"> {{ record.date }}</span>
    <span slot="payment_status" slot-scope="record">{{ record.payment_status ? 'Оплачено' : 'Ожидает оплаты' }}</span>
    <span slot="balance" slot-scope="record"> {{ record.balance }}</span>
    <span slot="action" slot-scope="record">
              <a-popconfirm
                title="Вы уверенны что хотите удалить?"
                ok-text="Да"
                cancel-text="Нет"
                @confirm="$emit('removeItem', record.id)"
              >
              <a href="javascript: void(0);" class="btn btn-sm btn-light">
                <small>
                  <i class="fe fe-trash mr-2" />
                </small>
                Удалить
              </a>
            </a-popconfirm>
          </span>
  </a-table>
</template>

<script>

export default {
  name: 'ClientsBillsTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return {}
      },
    },
    isOverview: {
      type: String,
      default: '',
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [],
    }
  },
  created() {
    this.setColumns()
  },
  methods: {
    setColumns() {
      const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Наименование',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Сумма',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Дата',
          dataIndex: 'date',
        },
        {
          title: 'Остаток',
          scopedSlots: { customRender: 'balance' },
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'payment_status' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ]

      if (this.isOverview) columns.splice(6, 1)

      this.columns = columns
    },
    toEditPage(record) {
      this.$router.push({ path: `/users/clients/${record.id}` })
    },
  },
  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
