<template>
  <a-modal
    v-model="visible"
    :title="'Ручное пополнение баланса'"
    width="40%"
    :afterClose="close"
  >
    <div class="input-box">
      <label>
           <span class="lable">Сумма, грн</span>
        <input
          v-model="sum"
          class="inputSum"
          type="number"
          min="1"
          oninput="validity.valid||(value='');"
      /></label>
    </div>
    <template slot="footer">
      <a-button key="back" @click="addPayment" :disabled="sum === ''"> Пополнить </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'addClientsPaymentModal',
  data() {
    return {
      visible: false,
      sum: '',
      userId: null,
    }
  },
  methods: {
    async  addPayment() {
      const serverData = {
        sum: Number(this.sum),
      }
      try {
        await this.$services.put(`/admin/users/${this.userId}/replenish`, serverData)

        this.close()
      } catch (e) {
        console.log(e)
      }
    },

    open(id) {
      this.visible = true
      this.userId = id
    },
    close() {
      this.visible = false
      this.sum = ''
      this.userId = null
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal {
  padding: 24px 16px;
  .ant-modal-title {
    font-weight: bolder;
    font-size: 24px;
    color: #000;
  }

  .ant-modal-header {
    display: flex;
    justify-content: center;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }
}

::v-deep .ant-btn {
    color: #fff;
    background-color: #4CAD33;
    width: 30%;
}

.input-box {
  display: flex;
  justify-content: center;
}

.lable {
  font-size: 20px;
  color: #000;
  margin-right: 20px;
}

.inputSum{
    height: 35px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
