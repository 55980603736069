export default [
  {
    name: 'Заблокирован',
    id: 1,
  },
  {
    name: 'Активен',
    id: 0,
  },
]
